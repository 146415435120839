import React from "react";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

export interface confirmDialogProps {
    props: {
        open: boolean,
        handleModal: React.Dispatch<React.SetStateAction<boolean>>,
        onRegist: any,
        mode: string,
        body: string,
        confirmRestart: string,
        confirmCancel: string,
        confirmOk: string,
        confirmCancelRegist: string,
    }
}

const ConfirmDialog: React.FC<confirmDialogProps> = ({ props }) => {
    const [open, setOpen] = React.useState(false);

    
    const handleClick = (target: string) => {
        props.onRegist(props.mode + "_" + target)
    };


    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open])


    return (
        <div>
            <Dialog
                open={open}
                keepMounted
                // onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <Box sx={{ margin: ' 20px' }}>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description" sx={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>{props.body}</DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'space-around' }}>
                    {
                            props.mode === "back" &&
                            <Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <Button variant="contained" color="success" size="small" sx={{ margin: '10px' }} onClick={(e) => {handleClick("restart")}}>{props.confirmRestart}</Button>
                                    <Button variant="outlined" color="error" size="small" sx={{ margin: '10px' }} onClick={(e) => {handleClick("cancel")}}>{props.confirmCancel}</Button>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button variant="contained" color="error" size="small" sx={{ margin: '10px' }} onClick={(e) => {handleClick("regist")}}>{props.confirmOk}</Button>
                                </Box>
                            </Box>
                        }
                        {
                            props.mode =="end_count_time" &&
                            <Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <Button variant="outlined" color="error" size="small" sx={{ margin: '10px' }} onClick={(e) => {handleClick("cancel")}}>{props.confirmCancel}</Button>
                                    <Button variant="contained" color="success" size="small" sx={{ margin: '10px' }} onClick={(e) => {handleClick("regist")}}>{props.confirmCancelRegist}</Button>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button variant="contained" color="success" size="small" sx={{ margin: '10px' }} onClick={(e) => {handleClick("arbitraryMeasurement")}}>{props.confirmOk}</Button>
                                </Box>
                            </Box>
                        }
                        {
                            props.mode =="finishiArbitrary" &&
                            <Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <Button variant="outlined" color="error" size="small" sx={{ margin: '10px' }} onClick={(e) => {handleClick("cancel")}}>{props.confirmCancel}</Button>
                                    <Button variant="contained" color="success" size="small" sx={{ margin: '10px' }} onClick={(e) => {handleClick("regist")}}>{props.confirmCancelRegist}</Button>
                                </Box>
                            </Box>
                        }
                        {
                            props.mode =="cancelConfirm" &&
                            <Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <Button variant="outlined" color="error" size="small" sx={{ margin: '10px' }} onClick={(e) => {handleClick("cancel")}}>{props.confirmCancel}</Button>
                                    <Button variant="contained" color="success" size="small" sx={{ margin: '10px' }} onClick={(e) => {handleClick("regist")}}>{props.confirmCancelRegist}</Button>
                                </Box>
                            </Box>
                        }
                        {
                            props.mode =="finish" &&
                            <Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button variant="contained" color="success" size="small" sx={{ margin: '10px' }} onClick={(e) => {handleClick("regist")}}>{props.confirmOk}</Button>
                                </Box>
                            </Box>
                        }
                        {
                            props.mode =="error" &&
                            <Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button variant="contained" color="success" size="small" sx={{ margin: '10px' }} onClick={(e) => {handleClick("regist")}}>{props.confirmOk}</Button>
                                </Box>
                            </Box>
                        }
                    </DialogActions>
                </Box>
            </Dialog>
        </div>
    );

}

export default ConfirmDialog;


