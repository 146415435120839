import * as React from 'react';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import {
    Box,
    Typography,
    TextField,
    Select,
    MenuItem,
    SelectChangeEvent,
    Button,
} from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import { setReceptionBook } from '../../../features/receptionBookSlice';

export interface resultProps {
    props:{
    }
}

const TrafficVolume:React.FC<resultProps> = ({props}) => {
    const dispatch = useAppDispatch();

    //URLパラメータ
    const [searchParams] = useSearchParams();
    const planAreaId = searchParams.get("planAreaId");
    const referrer = searchParams.get("referrer");
    
    const [measurementTime, setMeasurementTime] = React.useState("")
    const [measurementType, setMeasurementType] = React.useState("0")

    const objReceptionBook = useAppSelector((state) => state.receptionBook.select);
    const rviewStoreCode = useAppSelector((state) => state.rviewStoreData.storeCode);
    const backRef = React.useRef<HTMLDivElement>(null);
    const rviewbackRef = React.useRef<HTMLDivElement>(null);
    const rviewStoreBackRef = React.useRef<HTMLDivElement>(null);


    React.useEffect(() => {
        ((async) => {
            dispatch(setReceptionBook({
                ...objReceptionBook,
                plan_area_id: planAreaId,
            }))
        })();
    }, [])

    const handleSelectChange = (e: SelectChangeEvent<string>) => {
        setMeasurementType(e.target.value)
    };

    const REGEX_NUMBER = /^[0-9]*$/g;
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (!e.target.value.match(REGEX_NUMBER)) {
            return
        }
        setMeasurementTime(e.target.value)
    }

    const checkDisabled = () => {
        if (measurementTime === '') {
            return true
        }

        if (isNaN(Number(measurementTime))) {
            return true
        }

        return false
    }

    const clickHandle = () => {
        if (!checkDisabled()) {
          const link = document.getElementById("trafficVolume-counter");
          if (link) {
              link.click()
          }  
        }
    }

    const clickBack = () => {

        if (referrer === "") {
            if (backRef) {
                backRef.current?.click();
            }
        } else if (referrer === "rview") {
            if (rviewbackRef) {
                rviewbackRef.current?.click();
            }            
        } else if (referrer === "rview-store") {
            if (rviewStoreBackRef) {
                rviewStoreBackRef.current?.click();
            }
        }
    }
    
    return (
        <Box style={{ width: "100%", margin: 0, padding: 0, position: 'relative' }}>
            <Box sx={{margin: '0 10px'}}>
                <Button
                    variant="outlined"
                    size="small"
                    sx={{margin: '4px', minWidth: '30px', padding: '10px 0',
                    boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'
                     }}
                    onClick={clickBack}
                >≪</Button>
                {
                    referrer === "" &&
                    <Link to="/"><div ref={backRef}></div></Link>
                }
                {
                    referrer === "rview" &&
                    <Link to={"/Planareamenu?planAreaId="+planAreaId}><div ref={rviewbackRef}></div></Link>
                }
                {
                    referrer === "rview-store" &&
                    <Link to={"/StoreMenu?storecode="+rviewStoreCode}><div ref={rviewStoreBackRef}></div></Link>
                }
            </Box>
            <Box sx={{height: '30px', margin: '20px'}}>
                <Typography>{objReceptionBook.plan_area_id}</Typography>
            </Box>
            <Box sx={{height: '400px'}}>
                <Box sx={{margin: '20px'}}>
                    <Typography>測定時間（分）</Typography>
                    <TextField
                        type="number"
                        onChange={handleInputChange}
                        value={measurementTime}
                    ></TextField>
                </Box>
                <Box sx={{margin: '20px'}}>
                    <Typography>測定種別</Typography>
                    <Select
                        onChange={handleSelectChange}
                        value={measurementType}
                        ><MenuItem value="0">車＋人（5m内外）</MenuItem>
                        <MenuItem value="3">車＋人（5m内）</MenuItem>
                        <MenuItem value="1">車のみ</MenuItem>
                        <MenuItem value="2">人のみ</MenuItem>
                    </Select>
                </Box>
            </Box>
            <Box sx={{ width: '100%', height: '60px', position: 'fixed', left: 0, bottom: 0, margin: '10px'}}>
                <Link
                    id="trafficVolume-counter"
                    to={"/TrafficVolume/regist"}
                    state={{ planAreaId: objReceptionBook.plan_area_id, referrer: referrer, measurementTime: measurementTime, measurementType: measurementType }}>
                </Link>
                <Button variant="contained" color="success" size="small"
                    disabled={checkDisabled()}
                    sx={{width: '95%', height: '55px'}}
                    onClick={clickHandle}
                >カウンター</Button>
            </Box>
        </Box>
    );
        
}


export default TrafficVolume;
