import React from "react";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

export interface resetProps {
  props:{
    open:boolean,
    handleModal:React.Dispatch<React.SetStateAction<boolean>>,
    onCancel:any,
    onExecution:any,
  }
}

const Reset:React.FC<resetProps> = ({props}) => {
    const [open, setOpen] = React.useState(false);

    React.useEffect(() =>{
        setOpen(props.open);
    },[props.open])

    const handleClose = () => {
        props.onCancel()
    };
    
    const handleOk = () => {
        props.onExecution()
    };

    return (
        <Box>
        <Dialog
            open={open}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
        >
            <Box sx={{ margin: ' 20px' }}>
                <DialogContent>
                <DialogContentText id="alert-dialog-slide-description" sx={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>{"本当にリセットしても\n良いですか？"}</DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-around' }}>
                    <Button variant="outlined" color="error" size="small" onClick={handleClose}>キャンセル</Button>
                    <Button variant="contained" color="error" size="small" onClick={handleOk}>リセット</Button>
                </DialogActions>
            </Box>
        </Dialog>
        </Box>
    )
}

export default Reset;
